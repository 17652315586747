import "firebase/auth";
import "firebase/functions";
import "./firebase-ui-styling.global.scss";
import "./components/Card.scss";

import firebase from "firebase/app";
import firebaseui from "firebaseui";
import React from "react";
import FirebaseAuth from "react-firebaseui/FirebaseAuth";

import { UserState } from "./db";

interface LoginProps {
  isActive: boolean;
  onLogin: () => void;
  userState: UserState;
  CTA?: string | null;
}

interface LoginState {
  isLoginPending: boolean;
}

export default class Login extends React.PureComponent<LoginProps, LoginState> {
  constructor(props: LoginProps) {
    super(props);
    this.state = {
      isLoginPending: false,
    };
  }

  componentDidUpdate = (prevProps: LoginProps) => {
    if (
      prevProps.userState !== this.props.userState &&
      this.state.isLoginPending
    ) {
      this.setState({ isLoginPending: false });
    }
  };

  render = () => {
    const { isActive, onLogin, userState, CTA } = this.props;
    const uiConfig: firebaseui.auth.Config = {
      autoUpgradeAnonymousUsers: true,
      callbacks: {
        signInFailure: async ({ code, credential }) => {
          // TODO Should be able to just remove all this.
          if (code !== "firebaseui/anonymous-upgrade-merge-conflict") {
            return;
          }

          const auth = firebase.auth();

          console.log("Merging old account:", auth.currentUser!.uid);
          const anonIDToken = await auth.currentUser!.getIdToken(true);
          await auth.signInAndRetrieveDataWithCredential(credential);
          console.log("With new account:", auth.currentUser!.uid);

          console.log("Merging...");
          const mergeAccounts = firebase
            .functions()
            .httpsCallable("mergeAccounts");
          await mergeAccounts(anonIDToken)
            .then(result => {
              this.setState({ isLoginPending: true });
              onLogin();
            })
            .catch(error => {
              console.log(
                "Merging error",
                error.code,
                error.message,
                error.details,
              );
            });
        },
        signInSuccessWithAuthResult: authResult => {
          this.setState({ isLoginPending: true });
          firebase.functions().httpsCallable("didRegister")();
          onLogin();
          return false;
        },
      },
      signInOptions: [
        {
          provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
          requireDisplayName: false,
        },
        {
          provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          scopes: ["https://www.googleapis.com/auth/userinfo.email"],
        },
      ],
      credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO,
      signInFlow: "popup",
      tosUrl: "/terms",
      privacyPolicyUrl: "/terms",
    };

    const isLoggedIn = this.state.isLoginPending || userState === "registered";

    return (
      <div className="LoginWrapper">
        <div
          style={{
            opacity: isLoggedIn ? 0 : 1,
            transition: "opacity 0.2s linear",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            flexDirection: "column",
            display: "flex",
          }}
        >
          {CTA !== null && (
            <div className="LoginCTA">
              {CTA ||
                "Please sign in so we can save your progress and let you know the best times to review."}
            </div>
          )}
          {isActive && (
            <FirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
          )}
        </div>
        <div
          style={{
            opacity: isLoggedIn ? 1 : 0,
            pointerEvents: isLoggedIn ? "unset" : "none",
            transition: "opacity 0.2s linear",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            flexDirection: "column",
            display: "flex",
          }}
        >
          <div className="LoginConfirmation">
            <div className="LoginConfirmationGlyph" />
            <div>Thank you! Your progress will be saved as you read.</div>
          </div>
        </div>
      </div>
    );
  };
}
