import "./components/ReviewArea.scss"; // bit of a hack: implicitly depends on these styles
import "intersection-observer";

import React, { useRef } from "react";
import { useInViewport } from "react-in-viewport";

import { UserState } from "./db";
import Login from "./Login";

interface Props {
  onLogin: () => void;
  initialUserState: UserState;
  userState: UserState;
  CTA?: string | null;
}

const FreefloatingLoginUpsell = ({
  onLogin,
  initialUserState,
  userState,
  CTA,
}: Props) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { inViewport } = useInViewport(
    containerRef,
    { rootMargin: "200px" },
    { disconnectOnLeave: false },
    {},
  );

  if (initialUserState === "registered") {
    return null;
  } else {
    return (
      <div className="FreefloatingLoginUpsell" ref={containerRef}>
        <div className="CardDynamicsContainer">
          <div className="Card LoginCard">
            <Login
              userState={userState}
              isActive={userState === "anonymous" && !!inViewport}
              onLogin={onLogin}
              CTA={CTA}
            />
          </div>
        </div>
      </div>
    );
  }
};

export default FreefloatingLoginUpsell;
